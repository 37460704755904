import React from "react";

const ShareButtons = () => {
  const shareFacebook = () => {
    const url = encodeURIComponent(window.location.href);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      "_blank"
    );
  };

  const shareTwitter = () => {
    const url = encodeURIComponent(window.location.href);
    const title = encodeURIComponent(document.title);
    window.open(
      `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
      "_blank"
    );
  };

  const shareWhatsApp = () => {
    const url = encodeURIComponent(window.location.href);
    const title = encodeURIComponent(document.title);
    window.open(
      `https://api.whatsapp.com/send?text=${title}%20${url}`,
      "_blank"
    );
  };

  return (
    <>
      {/* Facebook */}
      <button className="share-btn" onClick={shareFacebook}>
        <i className="bi bi-facebook"></i>
      </button>

      {/* Twitter */}
      <button className="share-btn" onClick={shareTwitter}>
        <i className="bi bi-twitter"></i>
      </button>

      {/* WhatsApp */}
      <button className="share-btn" onClick={shareWhatsApp}>
        <i className="bi bi-whatsapp"></i>
      </button>
    </>
  );
};

export default ShareButtons;
