import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const AgeCalculator = () => {
  const pageTitle = 'Age Calculator - Calculate Your Age in Years, Months, and Days | onlinetoolsfor.com';
    const pageDescription = 'Use our free Age Calculator to easily calculate your age in years, months, and days. Simply enter your birth date and our calculator will do the rest. Try it out today on onlinetoolsfor.com!';


  const [birthDate, setBirthDate] = useState("");
  const [exactAge, setExactAge] = useState("");

  const calculateAge = () => {
    const birthdate = new Date(birthDate);
    const today = new Date();

    const ageInMilliseconds = today - birthdate;
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

    const age = {
      years: Math.floor(ageInYears),
      months: Math.floor((ageInYears % 1) * 12),
      days: Math.floor((ageInMilliseconds / (1000 * 60 * 60 * 24)) % 30.4375),
      hours: Math.floor((ageInMilliseconds / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((ageInMilliseconds / (1000 * 60)) % 60),
      seconds: Math.floor((ageInMilliseconds / 1000) % 60),
    };

    let ageString = "You are ";
    if (age.years > 0) {
      ageString += `${age.years} year${age.years > 1 ? "s" : ""}, `;
    }
    if (age.months > 0) {
      ageString += `${age.months} month${age.months > 1 ? "s" : ""}, `;
    }
    if (age.days > 0) {
      ageString += `${age.days} day${age.days > 1 ? "s" : ""}, `;
    }
    if (age.hours > 0) {
      ageString += `${age.hours} hour${age.hours > 1 ? "s" : ""}, `;
    }
    if (age.minutes > 0) {
      ageString += `${age.minutes} minute${age.minutes > 1 ? "s" : ""}, `;
    }
    ageString += `and ${age.seconds} second${age.seconds > 1 ? "s" : ""} old.`;

    setExactAge(ageString);
  };

  return (
    <>
    <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
        </Helmet>
      <header class="pt-5">
    <div class="container px-lg-5">
        <div class="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div class="m-4 m-lg-5">
                <h1 class="display-5 fw-bold">Age Calculator</h1>
            </div>
        </div>
    </div>
</header>
<div class="container px-lg-5">
    <nav aria-label="breadcrumb">
        <ol class="py-1 my-2 breadcrumb">
        <li class="breadcrumb-item"><Link to="/">Home</Link></li>
            <li class="breadcrumb-item"><Link to="/online-calculators">Online Calculators</Link></li>
            <li class="breadcrumb-item active" aria-current="page">Age Calculator</li>
        </ol>
    </nav>
</div>
{/* <!-- Page Content--> */}
<section class="pt-4 pb-5">
    <div class="container px-lg-5">
        {/* <!-- Page Features--> */}
        <div class="row gx-lg-5">
            <p class="mb-4">
                Welcome to <b>Onlinetoolsfor.com</b>'s <b>age calculator</b>, a highly valuable and advanced tool that accurately determines your age in years, months, days, hours, minutes, and seconds. Whether you require this tool for job applications, legal matters, health and fitness tracking, or simply personal knowledge, it provides precise and reliable outcomes. In this article, we will explain how to utilize the age calculator effectively and outline the importance it holds for you.
            </p>
            <br /><br />
    
      <div className="col-md-6 mx-auto">
        <div className="form-group">
          <label htmlFor="birth_date">
            <b>Enter your birthdate:</b>
          </label>
          <input
            type="date"
            id="birth_date"
            className="form-control"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
          />
        </div>
        <br />
        <button className="btn btn-primary" onClick={calculateAge}>
          Calculate
        </button>
        <div id="exact_age" className="mt-3">
          {exactAge}
        </div>
      </div>
      </div>
        <br />
        <br />
        <div class="row">
            <div class="col-md-12">
                <h2>How to Use the Age Calculator</h2>

                <p>
                    Using the age calculator tool provided by Onlinetoolsfor.com is simple and straightforward. Just follow these steps:
                </p>

                <ul>
                    <li>Visit Onlinetoolsfor.com/age-calculator.</li>
                    <li>Enter your birth date using the provided calendar.</li>
                    <li>Click the "Work out Age" button.</li>
                </ul>
                <p>
                    Upon pressing the button, the age calculator swiftly generates your age in various measurements, encompassing years, months, days, hours, minutes, and seconds. Additionally, this convenient tool extends its functionality to calculating the age of another individual, requiring only their birth date as input.
                </p>

                <h3>Why the Age Calculator is Invaluable</h3>

                <p>
                    The age calculator tool holds immense value and can be beneficial in various situations. Consider the following scenarios:
                </p>

                <ul>
                    <li>
                        <b>Job Applications:</b> When filling out employment forms that require your age, the age calculator enables you to determine your precise age, ensuring accurate information submission.
                    </li>
                    <li>
                        <b>Legal Matters:</b> In legal contexts like creating a will or filing for social security benefits, having an exact age is crucial. The age calculator ensures you provide the correct information for such processes.
                    </li>
                    <li>
                        <b>Health and Fitness:</b> Tracking your progress in health and fitness goals becomes more manageable when you know your accurate age. The age calculator helps you measure your achievements effectively.
                    </li>
                    <li>
                        <b>Birthday Planning:</b> Organizing a birthday celebration for someone becomes easier when you know their exact age. By utilizing the age calculator, you can plan a party that is perfectly tailored to their age and preferences.
                    </li>
                    <li>
                        <b>Personal Knowledge:</b> Knowing your age is a matter of personal awareness, and the age calculator helps you determine it accurately. It provides you with the satisfaction of knowing your precise age.
                    </li>
                </ul>

                <h2>Conclusion</h2>

                <p>
                    In conclusion, Onlinetoolsfor.com's age calculator is an invaluable and advanced tool that allows you to determine your age accurately in various units. Whether you require it for job applications, legal matters, health and fitness tracking, or personal knowledge, this tool offers a quick and easy way to calculate your age. Next time you want to know your age, be sure to visit Onlinetoolsfor.com/age-calculator and make use of our efficient age calculator.
                </p>
            </div>
        </div>
    </div>
</section>
    </>
  );
};

export default AgeCalculator;
