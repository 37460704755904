// SeoTools.js
import React from 'react'
import { Link } from 'react-router-dom'
import SeoToolsCardParent from '../SeoToolsCardParent';
import { Helmet } from 'react-helmet';

function SeoTools() {
    const pageTitle = 'Free SEO Tools for Improving Your Website | OnlineToolsFor.com';
    const pageDescription = 'Boost your website\'s search engine rankings with the best SEO tools available online. Our platform provides a wide range of tools to help you optimize your website and drive traffic to it.';
  return (
    <>
    <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
        </Helmet>
      <header class="pt-5">
        <div class="container px-lg-5">
            <div class="p-4 p-lg-5 bg-light rounded-3 text-center">
                <div class="m-4 m-lg-5">
                    <h1 class="display-5 fw-bold">SEO Tools</h1>
                    <p class="fs-4">
                        list of all Online SEO Tools.
                    </p>
                </div>
            </div>
        </div>
    </header>
    <div class="container px-lg-5 pb-5">
        <nav aria-label="breadcrumb">
            <ol class="py-1 my-2 breadcrumb">
                <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                <li class="breadcrumb-item active" aria-current="page">SEO Tools</li>
            </ol>
        </nav>
    </div>
      {/* <!-- Page Content--> */}
      <section className="pt-4">
          <div className="container px-lg-5">
              {/* <!-- Page Features--> */}
              <div className="row gx-lg-5">
                  <SeoToolsCardParent />
              </div>
          </div>
      </section>
    </>
  )
}

export default SeoTools;
