import React from 'react';
import * as XLSX from 'xlsx';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const ExcelConverter = () => {
  const pageTitle = 'Convert PHP Arrays to Excel with Ease using our PHP Array to Excel Converter | onlinetoolsfor.com';
    const pageDescription = 'Convert PHP arrays to Excel spreadsheets effortlessly using our PHP Array to Excel Converter. Optimize your workflow and save time by generating high-quality Excel sheets with ease.';



  const convertToExcel = (phpArray) => {

    // Check if the input is empty
    if (!phpArray.trim()) {
      alert('Please insert content before converting to Excel.');
      return;
    } 
    // Parse the PHP array string into a JavaScript array
    const dataArray = JSON.parse(phpArray);

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(dataArray);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Save the workbook to a file
    XLSX.writeFile(wb, 'output.xlsx');
  };

  return (
    <>
    <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
        </Helmet>
    <header className="pt-5">
    <div className="container px-lg-5">
        <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div className="m-4 m-lg-5">
                <h1 className="display-5 fw-bold">PHP Array to Excel Converter</h1>
            </div>
        </div>
    </div>
</header>
<div className="container px-lg-5">
    <nav aria-label="breadcrumb">
        <ol className="py-1 my-2 breadcrumb">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/web-tools">Web Tools</Link></li>
            <li className="breadcrumb-item active" aria-current="page">PHP Array to Excel Converter</li>
        </ol>
    </nav>
</div>
{/* <!-- Page Content--> */}
<section className="pt-4 pb-5">
    <div className="container px-lg-5">
        {/* <!-- Page Features--> */}
        <div className="row gx-lg-5">
            <p>
                PHP Array to Excel Converter helps you to instantly convert <b>PHP arrays into Excel</b> files using the free and simple PHP Array to Excel Converter from OnlineToolsFor.com. You can transform your data into a beautiful spreadsheet that is simple for others to use and evaluate with just a few clicks. Because it takes an expansive variety of formats and has an easy-to-use user interface, our solution is an ideal decision for anybody wishing to upgrade their information the executives cycle. See how simple it is to convert your PHP arrays to Excel files by giving it a try right now.
            </p>
            <br /><br /><br />
    <div className="col-md-12 mx-auto mt-5">
      <div>
        <label htmlFor="array-input"><h2>Paste your PHP array here:</h2></label>
        <textarea className="form-control" id="array-input" rows="8" required></textarea>
      </div>
      <br />
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => {
          const phpArrayInput = document.getElementById('array-input');
          convertToExcel(phpArrayInput.value);
        }}
      >
        Convert to Excel
      </button>
      <hr />
      <h5 className="mt-4">Sample PHP Array Format to Convert:</h5>
      <pre>
        {JSON.stringify([
          ['Name', 'Age', 'Gender'],
          ['John', 30, 'Male'],
          ['Jane', 25, 'Female'],
          ['Bob', 40, 'Male'],
        ])}
      </pre>
      {/* <h5 className="mt-4">Sample PHP Array Format 2:</h5>
      <pre>
        {JSON.stringify([
          ['Name', 'Age', 'Email'],
          ['John Doe', 30, 'john@example.com'],
          ['Jane Smith', 25, 'jane@example.com'],
          ['Bob Johnson', 45, 'bob@example.com'],
        ])}
      </pre> */}
    </div>
    <br />
            <div className="col-md-12">
            <br />
                <h2>Convert PHP Arrays to Excel with Ease using our PHP Array to Excel Converter</h2>
                <p>
                    You recognize the significance of equipping yourself with the right tools to amplify your efficiency as a developer. The PHP Array to Excel Converter is an invaluable tool for developers who want to convert PHP arrays to Excel spreadsheets effortlessly. You can undoubtedly make great Excel sheets with this tool, which will assist with helping your workflow and save you time.
                </p>
                <h2>Why Use Our PHP Array to Excel Converter?</h2>
                <p>
                    Our PHP Array to Excel Converter is a powerful tool that offers several benefits to developers. Firstly, it saves time by generating high-quality Excel sheets from PHP arrays quickly. This means developers don't have to spend hours manually writing code to create spreadsheets. Moreover, it guarantees precision throughout the conversion process, minimizing the potential for inaccuracies. Thirdly, the PHP Array to Excel Converter is user-friendly and can be used by developers with varying levels of expertise.
                </p>
                <h2>How to Use Our PHP Array to Excel Converter?</h2>
                <p>
                    Using our PHP Array to Excel Converter is easy. Simply input the PHP array and select the desired output format (Excel file format). The utility will produce a meticulously optimized Excel sheet that you can effortlessly download and seamlessly incorporate into your projects. This saves you time and effort that would some way or another be spent composing code manually.
                </p>
                <h2>Benefits of Our PHP Array to Excel Converter:</h2>
                <ul>
                    <li>Saves time by converting PHP arrays to Excel spreadsheets effortlessly.</li>
                    <li>Ensures accuracy in the conversion process, reducing the risk of errors.</li>
                    <li>Crafted to be intuitive and accessible, this tool accommodates developers across a spectrum of skill levels, fostering usability and proficiency regardless of expertise.</li>
                    <li>High-quality Excel sheets generated from PHP arrays.</li>
                </ul>
                <h2>Conclusion:</h2>
                <p>
                    The PHP Array to Excel Converter is a valuable tool that helps developers convert PHP arrays to Excel spreadsheets quickly and easily. This application allows programmers to rapidly produce high-quality Excel sheets, which improves productivity and saves time. Use our PHP Array to Excel Converter to make your work easier and more efficient.
                </p>
            </div>
        </div>
    </div>
   </section>
    </>
  );
};

export default ExcelConverter;
