import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const LoanCalculator = () => {
  const pageTitle = 'Loan Calculator - Calculate Your Monthly Payments and Interest Rates | onlinetoolsfor.com';
    const pageDescription = 'Use our free Loan Calculator to easily calculate your monthly payments and interest rates for various types of loans, including mortgages, auto loans, and personal loans. Simply enter the loan amount, interest rate, and loan term, and our calculator will do the rest. Our Loan Calculator also includes amortization tables and graphs to help you visualize your repayment schedule. Try it out today on onlinetoolsfor.com!';



  const [amount, setAmount] = useState("");
  const [interest, setInterest] = useState("");
  const [years, setYears] = useState("");
  const [monthlyPayment, setMonthlyPayment] = useState("");
  const [totalPayment, setTotalPayment] = useState("");
  const [totalInterest, setTotalInterest] = useState("");
  const [interestType, setInterestType] = useState("fixed");

  const calculateResults = () => {
    let principal = parseFloat(amount);
    let calculatedInterest = parseFloat(interest) / 100 / 12;
    const calculatedPayment = parseFloat(years) * 12;

    let x = Math.pow(1 + calculatedInterest, calculatedPayment);
    let monthly = (principal * x * calculatedInterest) / (x - 1);

    const total = monthly * calculatedPayment;

    if (interestType === "fixed") {
      calculatedInterest = parseFloat(interest) / 100 / 12;
      x = Math.pow(1 + calculatedInterest, calculatedPayment);
      monthly = (principal * x * calculatedInterest) / (x - 1);
    } else if (interestType === "floating") {
      calculatedInterest = parseFloat(interest) / 100;
      let totalInterestPaid = 0;

      for (let i = 1; i <= calculatedPayment; i++) {
        const monthlyInterest = (principal * calculatedInterest) / 12;
        totalInterestPaid += monthlyInterest;
        principal -= monthly - monthlyInterest;
      }

      monthly = total / calculatedPayment;
      setTotalInterest(totalInterestPaid.toFixed(2));
    }

    if (isFinite(monthly)) {
      setMonthlyPayment(monthly.toFixed(2));
      setTotalPayment(total.toFixed(2));

      if (interestType === "fixed") {
        setTotalInterest((total - principal).toFixed(2));
      }
    } else {
      showError("Please check your numbers");
    }
  };

  const showError = (error) => {
    const errorDiv = document.createElement("div");
    const card = document.querySelector(".card");
    const heading = document.querySelector(".heading");

    errorDiv.className = "alert alert-danger";
    errorDiv.appendChild(document.createTextNode(error));
    card.insertBefore(errorDiv, heading);

    setTimeout(clearError, 3000);
  };

  const clearError = () => {
    document.querySelector(".alert").remove();
  };

  return (
    <>
    <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
        </Helmet>
    <header class="pt-5">
    <div class="container px-lg-5">
        <div class="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div class="m-4 m-lg-5">
                <h1 class="display-5 fw-bold">Loan Calculator</h1>
            </div>
        </div>
    </div>
</header>
<div class="container px-lg-5">
    <nav aria-label="breadcrumb">
        <ol class="py-1 my-2 breadcrumb">
        <li class="breadcrumb-item"><Link to="/">Home</Link></li>
            <li class="breadcrumb-item"><Link to="/online-calculators">Online Calculators</Link></li>
            <li class="breadcrumb-item active" aria-current="page">Loan Calculator</li>
        </ol>
    </nav>
</div>
{/* <!-- Page Content--> */}
<section class="pt-4 pb-5">
    <div class="container px-lg-5">
        {/* <!-- Page Features--> */}
        <div class="row gx-lg-5">
            <p class="mb-4">
                Welcome to <b>onlinetoolsfor.com</b>'s <b>loan calculator</b> page! We are delighted to offer you a user-friendly tool that simplifies loan calculations. With our loan calculator, you can effortlessly determine your monthly payment, total interest, and repayment amount. In this informative piece, we will delve into the workings of our calculator, its advantages, and why it serves as an indispensable asset for individuals seeking a loan.
            </p>
            <br /><br />
      <div className="col-md-8 mx-auto">
        <div className="card card-body">
          <form id="loan-form">
            <div className="form-group">
              <label>Loan Amount</label>
              <input
                type="number"
                className="form-control"
                id="amount"
                placeholder="Loan Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Interest Rate</label>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  id="interest"
                  step="0.01"
                  placeholder="Interest Rate"
                  value={interest}
                  onChange={(e) => setInterest(e.target.value)}
                />
                <div className="input-group-append">
                  <select
                    className="form-select"
                    id="interest-type"
                    value={interestType}
                    onChange={(e) => setInterestType(e.target.value)}
                  >
                    <option value="fixed">Fixed</option>
                    <option value="floating">Floating</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Loan Term</label>
              <input
                type="number"
                className="form-control"
                id="years"
                placeholder="Loan Term"
                value={years}
                onChange={(e) => setYears(e.target.value)}
              />
            </div>
            <br />
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={calculateResults}
              >
                Calculate
              </button>
            </div>
          </form>
          <div className="results mt-4">
            <h5>Results</h5>
            <div className="form-group">
              <label>Monthly Payment</label>
              <input
                type="text"
                className="form-control"
                id="monthly-payment"
                disabled
                value={monthlyPayment}
              />
            </div>
            <div className="form-group">
              <label>Total Payment</label>
              <input
                type="text"
                className="form-control"
                id="total-payment"
                disabled
                value={totalPayment}
              />
            </div>
            <div className="form-group">
              <label>Total Interest</label>
              <input
                type="text"
                className="form-control"
                id="total-interest"
                disabled
                value={totalInterest}
              />
            </div>
          </div>
        </div>
      </div>
      </div>
        <br />
        <br />
        <div class="row">
            <div class="col-md-12">
                <h2>Understanding Loan Calculators</h2>
                <p>
                    A loan calculator proves to be a useful instrument that aids in approximating loan payments by taking into consideration variables such as the loan amount, interest rate, and duration of repayment. At onlinetoolsfor.com, we have developed a loan calculator that delivers accurate and reliable calculations, helping you gain insights into the financial impact of your loan.
                </p>

                <h2>How to Use Our Loan Calculator</h2>
                <p>
                    Using our loan calculator is a breeze! Follow these simple steps to calculate your loan payments:
                </p>

                <h3>Step 1: Enter the Loan Amount</h3>
                <p>
                    Begin by entering the desired loan amount you wish to borrow. Whether it's the full loan amount or a specific portion, our calculator adapts to your needs.
                </p>

                <h3>Step 2: Enter the Interest Rate</h3>
                <p>
                    Next, input the interest rate associated with your loan. This rate can be either fixed or variable, depending on your loan terms.
                </p>

                <h3>Step 3: Enter the Loan Term</h3>
                <p>
                    Specify the duration over which you plan to repay the loan. This timeframe, known as the loan term or repayment period, plays a significant role in determining your payment schedule.
                </p>

                <h3>Step 4: Click Calculate</h3>
                <p>
                    Upon inputting the essential information, simply click the designated "calculate" button to initiate the computation process. Our loan calculator will instantly generate your monthly payment, total interest, and repayment amount, providing you with valuable information to make informed financial decisions.
                </p>

                <h2>Advantages of Our Loan Calculator</h2>
                <p>
                    Our loan calculator offers numerous advantages, making it an indispensable tool for your financial planning:
                </p>

                <h3>User-Friendly Interface</h3>
                <p>
                    We have designed our loan calculator with simplicity in mind. Its intuitive interface ensures that anyone, regardless of their financial expertise, can navigate and utilize it effortlessly.
                </p>

                <h3>Accurate Results</h3>
                <p>
                    Precision is crucial when it comes to financial calculations. Utilizing our loan calculator grants you access to accurate projections of your monthly payment, total interest, and overall repayment sum, empowering you with a comprehensive comprehension of the financial ramifications entailed by your loan.
                </p>

                <h3>Time-Saving Tool</h3>
                <p>
                    Gone are the days of manual calculations and complex formulas. Our loan calculator saves you valuable time by delivering instant results, eliminating the need for laborious computations.
                </p>

                <h3>Conclusion</h3>
                <p>
                    In conclusion, our user-friendly loan calculator is a powerful tool that simplifies the loan planning process. By utilizing our calculator, you can accurately determine your monthly payments, total interest, and repayment amount, empowering you to make well-informed financial decisions. Whether you are budgeting, comparing loan options, or strategizing your repayment, our loan calculator is here to assist you.
                </p>

                <p>
                    Visit onlinetoolsfor.com's loan calculator page today and experience hassle-free loan planning. We appreciate your trust in our services and hope that our loan calculator provides you with the necessary insights to achieve your financial goals. Take control of your loan planning with ease by using our loan calculator now!
                </p>
            </div>
        </div>
    </div>
</section>
    </>
  );
};

export default LoanCalculator;
