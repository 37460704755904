// CssGenerators.js
import React from 'react'
import { Link } from 'react-router-dom'
import CssGeneratorsCardParent from '../CssGeneratorsCardParent';
import { Helmet } from 'react-helmet';

function CssGenerators() {
    const pageTitle = 'CSS Generators Online - Free Tools to Create CSS Code | OnlineToolsFor.com';
    const pageDescription = 'Easily create CSS code with OnlineToolsFor.com\'s free online CSS generators. Online tools for CSS generators. Choose from a variety of tools to customize your web design.';
  return (
    <>
    <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
        </Helmet>
      <header className="pt-5">
        <div className="container px-lg-5">
            <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                <div className="m-4 m-lg-5">
                    <h1 className="display-5 fw-bold">Online CSS Generators</h1>
                    <p className="fs-4">
                        List of all Online CSS Generator tools.
                    </p>
                </div>
            </div>
        </div>
      </header>
      <div className="container px-lg-5 pb-5">
          <nav aria-label="breadcrumb">
              <ol className="py-1 my-2 breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Online CSS Generators</li>
              </ol>
          </nav>
      </div>
      {/* <!-- Page Content--> */}
      <section className="pt-4">
          <div className="container px-lg-5">
              {/* <!-- Page Features--> */}
              <div className="row gx-lg-5">
                  <CssGeneratorsCardParent />
              </div>
          </div>
      </section>
    </>
  )
}

export default CssGenerators;
