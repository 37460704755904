// WebtoolsCardParent.js
import React from "react";
import WebtoolsCard from "./WebtoolsCard";

const WebtoolsCardData = [
  {
    iconClass: "bi bi-plus-circle",
    title: "Word and Character Counter",
    link: "word-character-counter",
    description:
      "Easily count your words and characters with our Word and Character Counter tool. Try it now to simplify your content creation process!",
  },
  {
    iconClass: "bi bi-arrow-left-right",
    title: "Case Changer Tool",
    link: "case-changer-tool",
    description:
      "Change the case of your text instantly with our Case Changer Tool. Give it a try now and save time on manual text formatting!",
  },
  {
    iconClass: "bi bi-fullscreen-exit",
    title: "CSS Minifier Tool",
    link: "css-minifier-tool",
    description:
      "Optimize your CSS code for faster loading times with our CSS Minifier Tool. Try it now and boost your website's performance!",
  },
  {
    iconClass: "bi bi-view-stacked",
    title: "CSS Beautifier",
    link: "css-beautifier-tool",
    description:
      "Format and beautify your CSS code with our CSS Beautifier Tool. Try it now and enhance your code's readability!",
  },
  {
    iconClass: "bi bi-file-excel",
    title: "PHP Array to Excel Converter",
    link: "php-array-to-excel",
    description:
      "Convert PHP arrays to Excel spreadsheets easily with our Array to Excel Converter. Save time and improve efficiency!",
  },
  // {
  //   iconClass: "bi bi-calendar3",
  //   title: "PHP Date Format Code Generator",
  //   link: "php-date-format-code-generator",
  //   description:
  //     "Create custom date formats with ease using our PHP code generator - the ultimate time-saving tool for developers.",
  // },
  {
    iconClass: "bi bi-arrow-left-right",
    title: "Image to WebP Converter",
    link: "image-to-webp-converter",
    description:
      "Convert your images to WEBP file in seconds - Free online WebP converter lets you convert your images to the WebP format",
  },

  // Add more data items as needed
];

const WebtoolsCardParent = () => {
  return (
    <div className="row">
      {WebtoolsCardData.map((feature, index) => (
        <WebtoolsCard key={index} {...feature} />
      ))}
    </div>
  );
};

export default WebtoolsCardParent;
