import React, { useState } from "react";
import "./case-changer.css";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const CaseChanger = () => {
  const pageTitle = 'Case Changer Tool Online- Convert Text to Uppercase or Lowercase | OnlineToolsFor.com';
    const pageDescription = 'Convert text to uppercase, lowercase, or title case with our free online Case Changer Tool. Easy to use, no sign-up required. Try it now at OnlineToolsFor.com.';

  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");

  const toUppercase = (text) => {
    return text.toUpperCase();
  };

  const toLowercase = (text) => {
    return text.toLowerCase();
  };

  const toSentenceCase = (text) => {
    let sentences = text.split(". ");
    let sentenceCasedSentences = [];

    for (let i = 0; i < sentences.length; i++) {
      let sentence = sentences[i];
      let words = sentence.split(" ");
      let sentenceCasedWords = [];

      sentenceCasedWords.push(
        words[0][0].toUpperCase() + words[0].slice(1).toLowerCase()
      );

      for (let j = 1; j < words.length; j++) {
        sentenceCasedWords.push(words[j].toLowerCase());
      }

      sentenceCasedSentences.push(sentenceCasedWords.join(" "));
    }

    return sentenceCasedSentences.join(". ");
  };

  const toTitleCase = (text) => {
    let words = text.toLowerCase().split(" ");
    let titleCaseWords = [];

    for (let i = 0; i < words.length; i++) {
      titleCaseWords.push(words[i][0].toUpperCase() + words[i].slice(1));
    }

    return titleCaseWords.join(" ");
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleCaseChange = (caseType) => {
    switch (caseType) {
      case "uppercase":
        setOutputText(toUppercase(inputText));
        break;
      case "lowercase":
        setOutputText(toLowercase(inputText));
        break;
      case "sentencecase":
        setOutputText(toSentenceCase(inputText));
        break;
      case "titlecase":
        setOutputText(toTitleCase(inputText));
        break;
      default:
        setOutputText("");
    }
  };

  return (
    <>
    <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
        </Helmet>
    <header className="pt-5">
        <div className="container px-lg-5">
            <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                <div className="m-4 m-lg-5">
                    <h1 className="display-5 fw-bold">Case Changer Tool</h1>
                </div>
            </div>
        </div>
    </header>
    <div className="container px-lg-5">
        <nav aria-label="breadcrumb">
            <ol className="py-1 my-2 breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/web-tools">Web Tools</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Case Changer Tool</li>
            </ol>
        </nav>
    </div>
    <section className="pt-4 pb-5">
      <div className="container px-lg-5">
        <div className="row gx-lg-5">
          <p>
            Our <b>Case Changer tool</b>, available at <b>onlinetoolsfor.com</b>
            , can quickly convert your text to Uppercase, Lowercase, Sentence
            Case, or Title Case. Just copy and paste the text you wish to adjust
            into the tool, pick the ideal change choice, and the tool will
            rapidly make the changed over text for you. Use it to save time and
            effort on text conversions!
          </p>
          <br />
          <br />
          <div className="col-md-10 mx-auto mt-5">
            <div className="form-group">
              <textarea
                className="form-control"
                value={inputText}
                onChange={handleInputChange}
                rows="5"
              ></textarea>
            </div>
            <div className="form-group text-center my-4">
              <button
                className="btn btn-primary mr-3"
                onClick={() => handleCaseChange("uppercase")}
              >
                Uppercase
              </button>
              <button
                className="btn btn-primary mr-3"
                onClick={() => handleCaseChange("lowercase")}
              >
                Lowercase
              </button>
              <button
                className="btn btn-primary mr-3"
                onClick={() => handleCaseChange("sentencecase")}
              >
                Sentence Case
              </button>
              <button
                className="btn btn-primary"
                onClick={() => handleCaseChange("titlecase")}
              >
                Title Case
              </button>
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                value={outputText}
                rows="5"
                readOnly
              ></textarea>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row">
            <div className="col-md-12">
                <h2>Case Changer Tool: Change Text Case in a Flash</h2>
                <p>
                    Are you tired of manually changing the case of your text? Whether you want to switch all lowercase text over completely to uppercase or capitalize the first letter of each word, a case changer tool can make the process quicker and more productive.
                </p>
                <p>
                    At its core, a case changer tool is a simple online utility that can quickly modify the case of any text that you input. With a couple of snaps, you might change the case of text from lowercase to uppercase, capitalize each word, or even join uppercase and lowercase characters.
                </p>
                <h2>What Can You Do With a Case Changer Tool?</h2>
                <p>
                    The possibilities are endless! Here are just a few examples of how you can use a case changer tool:
                </p>
                <ul>
                    <li>Convert all lowercase text to uppercase for titles and headings</li>
                    <li>Change the case of text to conform with specific style guidelines (e.g. sentence case, title case, etc.)</li>
                    <li>Convert all uppercase text to lowercase for simpler meaningfulness</li>
                    <li>Capitalize the first letter of each word to enhance the readability of text</li>
                </ul>
                <h2>How Does a Case Changer Tool Work?</h2>
                <p>
                    Using a case changer tool is incredibly simple. You should simply enter the text you need to alter, select the ideal case change, and snap the "Change Case" button. The tool will then instantly modify your text based on your selected options.
                </p>
                <h2>Why Should You Use a Case Changer Tool?</h2>
                <p>
                    A case changer tool can save you time and effort when it comes to modifying the case of your text. Instead of manually modifying the case of every single letter or word, you have the option to depend on an automated tool to handle the task on your behalf. This can be particularly useful for writers, editors, and anyone who works with text regularly.
                </p>
            
                <h3>In Conclusion</h3>
                <p>
                    A case changer tool is a useful online utility that can help you modify the case of any text quickly and efficiently. Whether you want to switch all lowercase text over completely to uppercase or capitalize the first letter of each word, a case changer tool can assist you with accomplishing your ideal outcome in only a couple of snaps. So why not try one out today and experience the benefits for yourself?
                </p>
            </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default CaseChanger;
