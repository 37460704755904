import React from "react";
import ShareButtons from "./ShareButtons";
import { Link } from "react-router-dom";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div class="share-container">
        <h4>Share</h4>
        <ShareButtons />
      </div>
      {/* <!-- Footer--> */}
      <footer class="py-5 bg-dark">
        <div class="container">
          <p class="text-center text-white">Copyright @ {currentYear}</p>
          <ul class="list-inline text-center text-white">
            <li class="list-inline-item">
              <Link to="privacy-policy">Privacy Policy</Link>
            </li>
            <li class="list-inline-item">
              <Link to="terms-conditions">Terms & Conditions</Link>
            </li>
            <li class="list-inline-item">
              <Link to="contact">Contact</Link>
            </li>
          </ul>
        </div>
      </footer>
      {/* <!-- Bootstrap core JS--> */}
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>

      {/* <!-- Core theme JS--> */}
      {/* <!-- <script src="js/bootstrap.bundle.min.js"></script> --> */}
      {/* <!-- <script src="assets/js/theme.min.js"></script> --> */}
      {/* <!-- <script src="assets/js/clipboard.min.js"></script> --> */}
      <script src="assets/js/prism.js"></script>
      {/* <!-- <script src="assets/js/custom.js"></script> --> */}

      {/* <!-- <script src="assets/js/scripts.js"></script> --> */}
    </>
  );
}

export default Footer;
