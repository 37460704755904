import React, { useState, useEffect } from 'react';
import './button-generator.css'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';


const ButtonStyler = () => {
  const pageTitle = 'CSS Button Generator - Free Button Generator Tool | OnlineToolsFor.com';
    const pageDescription = 'Easily create custom CSS buttons with our free CSS button generator. Choose from a range of styles and effects to create call-to-action buttons that will enhance your web design projects.';



  const [buttonType, setButtonType] = useState('solid');
  const [solidBgColor, setSolidBgColor] = useState('#00ff00');
  const [solidHoverColor, setSolidHoverColor] = useState('#007700');
  const [gradientTopColor, setGradientTopColor] = useState('#00ff00');
  const [gradientBottomColor, setGradientBottomColor] = useState('#007700');
  const [gradientTopHoverColor, setGradientTopHoverColor] = useState('#009900');
  const [gradientBottomHoverColor, setGradientBottomHoverColor] = useState('#005500');
  const [textColor, setTextColor] = useState('#000000');
  const [textHoverColor, setTextHoverColor] = useState('#333333');
  const [hasSelectedGradient, setHasSelectedGradient] = useState(false);

  // Border options
  const [borderStyle, setBorderStyle] = useState('none');
  const [borderWeight, setBorderWeight] = useState(1);
  const [borderColor, setBorderColor] = useState('#000000');
  const [borderRadius, setBorderRadius] = useState(5);

  // Padding options
  const [paddingTop, setPaddingTop] = useState(10);
  const [paddingRight, setPaddingRight] = useState(15);
  const [paddingBottom, setPaddingBottom] = useState(10);
  const [paddingLeft, setPaddingLeft] = useState(15);

  // Text Shadow options
  const [textShadowX, setTextShadowX] = useState(0);
  const [textShadowY, setTextShadowY] = useState(0);
  const [textShadowBlur, setTextShadowBlur] = useState(0);
  const [textShadowColor, setTextShadowColor] = useState('#000000');

  // Box Shadow options
  const [boxShadowX, setBoxShadowX] = useState(2);
  const [boxShadowY, setBoxShadowY] = useState(2);
  const [boxShadowBlur, setBoxShadowBlur] = useState(7);
  const [boxShadowColor, setBoxShadowColor] = useState('#000000');

  // Text Style options
  const [textAlign, setTextAlign] = useState('left');
  const [fontFamily, setFontFamily] = useState('Arial');
  const [fontSize, setFontSize] = useState(16);
  const [lineHeight, setLineHeight] = useState(1.5);

  const [generatedCSS, setGeneratedCSS] = useState('');

  useEffect(() => {
    const buttonPreview = document.getElementById('button-preview');

    

    const updatePreview = () => {
    
      buttonPreview.style.color = textColor;
      buttonPreview.style.textAlign = textAlign;
      buttonPreview.style.fontFamily = fontFamily;
      buttonPreview.style.fontSize = `${fontSize}px`;
      buttonPreview.style.lineHeight = lineHeight;

      if (buttonType === 'solid' || !hasSelectedGradient) {

        buttonPreview.style.backgroundColor = solidBgColor;
        buttonPreview.style.borderStyle = borderStyle;
        buttonPreview.style.borderWidth = `${borderWeight}px`;
        buttonPreview.style.borderColor = borderColor;
        buttonPreview.style.borderRadius = `${borderRadius}px`;
        buttonPreview.style.paddingTop = `${paddingTop}px`;
        buttonPreview.style.paddingRight = `${paddingRight}px`;
        buttonPreview.style.paddingBottom = `${paddingBottom}px`;
        buttonPreview.style.paddingLeft = `${paddingLeft}px`;
        buttonPreview.style.textShadow = `${textShadowX}px ${textShadowY}px ${textShadowBlur}px ${textShadowColor}`;
        buttonPreview.style.boxShadow = `${boxShadowX}px ${boxShadowY}px ${boxShadowBlur}px ${boxShadowColor}`;
        buttonPreview.style.backgroundImage = 'none';

        buttonPreview.addEventListener('mouseover', () => {
          buttonPreview.style.backgroundColor = solidHoverColor;
          buttonPreview.style.color = textHoverColor;
          buttonPreview.style.backgroundImage = 'none';
        });
        buttonPreview.addEventListener('mouseout', () => {
          buttonPreview.style.backgroundColor = solidBgColor;
          buttonPreview.style.color = textColor;
          buttonPreview.style.backgroundImage = 'none';
        });
      } else {
        const gradientValue = `linear-gradient(to bottom, ${gradientTopColor}, ${gradientBottomColor})`;
        buttonPreview.style.background = gradientValue;
        buttonPreview.style.borderStyle = borderStyle;
        buttonPreview.style.borderWidth = `${borderWeight}px`;
        buttonPreview.style.borderColor = borderColor;
        buttonPreview.style.borderRadius = `${borderRadius}px`;
        buttonPreview.style.paddingTop = `${paddingTop}px`;
        buttonPreview.style.paddingRight = `${paddingRight}px`;
        buttonPreview.style.paddingBottom = `${paddingBottom}px`;
        buttonPreview.style.paddingLeft = `${paddingLeft}px`;
        buttonPreview.style.textShadow = `${textShadowX}px ${textShadowY}px ${textShadowBlur}px ${textShadowColor}`;
        buttonPreview.style.boxShadow = `${boxShadowX}px ${boxShadowY}px ${boxShadowBlur}px ${boxShadowColor}`;

        buttonPreview.addEventListener('mouseover', () => {
          const hoverGradientValue = `linear-gradient(to bottom, ${gradientTopHoverColor}, ${gradientBottomHoverColor})`;
          buttonPreview.style.background = hoverGradientValue;
          buttonPreview.style.color = textHoverColor;
        });
        buttonPreview.addEventListener('mouseout', () => {
          buttonPreview.style.background = gradientValue;
          buttonPreview.style.color = textColor;
        });
      }
    };

    updatePreview(); // Initial update 

    const generateCSS = () => {
      const hoverStyles = buttonType === 'solid' ? `
        &:hover {
          background-color: ${solidHoverColor};
          color: ${textHoverColor};
        }
      ` : `
        &:hover {
          background: linear-gradient(to bottom, ${gradientTopHoverColor}, ${gradientBottomHoverColor});
          color: ${textHoverColor};
        }
      `;
    
      const cssCode = `
      .button-preview {
        background: ${buttonType === 'solid' ? solidBgColor : `linear-gradient(to bottom, ${gradientTopColor}, ${gradientBottomColor})`};
        color: ${textColor};
        text-align: ${textAlign};
        font-family: ${fontFamily};
        font-size: ${fontSize}px;
        line-height: ${lineHeight};
        border-style: ${borderStyle};
        border-width: ${borderWeight}px;
        border-color: ${borderColor};
        border-radius: ${borderRadius}px;
        padding: ${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px;
        text-shadow: ${textShadowX}px ${textShadowY}px ${textShadowBlur}px ${textShadowColor};
        box-shadow: ${boxShadowX}px ${boxShadowY}px ${boxShadowBlur}px ${boxShadowColor};
        transition: background-color 0.3s, color 0.3s;
      }
      
      /* Hover styles */
      .button-preview:hover {
        background-color: ${buttonType === 'solid' ? solidHoverColor : `linear-gradient(to bottom, ${gradientTopHoverColor}, ${gradientBottomHoverColor})`};
        color: ${textHoverColor};
      }
      
  
    
    `;
  
    setGeneratedCSS(cssCode);
  };

    generateCSS(); // Initial CSS code generation

    return () => {
      // Clean up event listeners when the component unmounts
      buttonPreview.removeEventListener('mouseover', null);
      buttonPreview.removeEventListener('mouseout', null);
    };

  }, [
    buttonType, solidBgColor, solidHoverColor, gradientTopColor, gradientBottomColor,
    gradientTopHoverColor, gradientBottomHoverColor, textColor, textHoverColor,
    hasSelectedGradient, borderStyle, borderWeight, borderColor, borderRadius,
    paddingTop, paddingRight, paddingBottom, paddingLeft,
    textShadowX, textShadowY, textShadowBlur, textShadowColor,
    boxShadowX, boxShadowY, boxShadowBlur, boxShadowColor,
    textAlign, fontFamily, fontSize, lineHeight
  ]);

  const handleRadioChange = (e) => {
    setButtonType(e.target.value);

    // Set the hasSelectedGradient flag when switching to gradient mode
    if (e.target.value === 'gradient') {
      setHasSelectedGradient(true);
    }
  };
  
  

  const handleCopyButtonClick = () => {
    // Create a textarea element to hold the CSS code
    const textarea = document.createElement('textarea');
    textarea.value = generatedCSS;
    document.body.appendChild(textarea);

    // Select the content of the textarea
    textarea.select();
    document.execCommand('copy');

    // Remove the textarea from the DOM
    document.body.removeChild(textarea);

    alert('CSS code copied to clipboard!');
  };

  return (
    <>
    <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
        </Helmet>
      <header className="pt-5">
          <div className="container px-lg-5">
              <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                  <div className="m-4 m-lg-5">
                      <h1 className="display-5 fw-bold">CSS Button Generator</h1>
                  </div>
              </div>
          </div>
      </header>
      <div className="container px-lg-5">
          <nav aria-label="breadcrumb">
              <ol className="py-1 my-2 breadcrumb">
              <li class="breadcrumb-item"><Link to="/">Home</Link></li>
            <li class="breadcrumb-item"><Link to="/css-generators">CSS Generators</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">CSS Button Generator</li>
              </ol>
          </nav>
      </div>
      <section className="pt-4 pb-5">
        <div className="container px-lg-5">
            {/* <!-- Page Features--> */}
            <div className="row gx-lg-5">
                <div className="col-md-12">
                    <p>
                        <b>CSS Button Generator</b> on <b>onlinetoolsfor.com</b> is an user-friendly tool allows you to effortlessly customize buttons with different colors, sizes, and styles. With real-time preview functionality, you can see your changes come to life before generating the CSS code. And the best part is, you can use it for free! In this article, we'll delve into the CSS Button Generator, exploring its features and how it can help you craft professional-looking buttons without any hassle.
                    </p>
                    <br /> 
                </div>
                <div className="col-md-4">
                    <button id="button-preview" className="btn sticky-top preview-button button-preview mb-3">
                      Preview Button
                    </button>
                </div>
                <div className="col-md-8">
                    <form id="button-form">
                        <div className="row mb-3">
                            <div>
                              <label className="form-check-inline">
                                <input className="form-check-input" type="radio" name="button-type" value="solid" checked={buttonType === 'solid'} onChange={handleRadioChange} />
                                &nbsp;Solid
                              </label>
                              <label className="form-check-inline">
                                <input className="form-check-input" type="radio" name="button-type" value="gradient" checked={buttonType === 'gradient'} onChange={handleRadioChange} />
                                &nbsp;Gradient
                              </label>
                            </div>
                        </div>
                        {buttonType === 'solid' && (
                          <>
                          <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">
                              Background Color:
                            </label>
                            <div className="col-sm-8">
                              <input className="form-control form-control-color" type="color" value={solidBgColor} onChange={(e) => setSolidBgColor(e.target.value)} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">
                              Background Hover Color:
                            </label>
                            <div className="col-sm-8">
                              <input className="form-control form-control-color" type="color" value={solidHoverColor} onChange={(e) => setSolidHoverColor(e.target.value)} />
                            </div>
                          </div>
                          </>
                        )}

                        {buttonType === 'gradient' && (
                          <>
                            <div  className="row mb-3">
                                <label for="top-gradient-color" className="col-sm-4 col-form-label">
                                  Top Gradient Color:
                                </label>
                                <div className="col-sm-8">
                                  <input className="form-control form-control-color" type="color" value={gradientTopColor} onChange={(e) => setGradientTopColor(e.target.value)} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label for="bottom-gradient-color" className="col-sm-4 col-form-label">
                                  Bottom Gradient Color:
                                </label>
                                <div className="col-sm-8">
                                  <input className="form-control form-control-color" type="color" value={gradientBottomColor} onChange={(e) => setGradientBottomColor(e.target.value)} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label for="top-Hovergradient" className="col-sm-4 col-form-label">
                                  Top Gradient Hover Color:
                                </label>
                                <div className="col-sm-8">
                                  <input className="form-control form-control-color" type="color" value={gradientTopHoverColor} onChange={(e) => setGradientTopHoverColor(e.target.value)} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label for="bottom-Hovergradient" className="col-sm-4 col-form-label">
                                  Bottom Gradient Hover Color:
                                </label>
                                <div className="col-sm-8">
                                  <input className="form-control form-control-color" type="color" value={gradientBottomHoverColor} onChange={(e) => setGradientBottomHoverColor(e.target.value)} />
                                </div>
                            </div>
                          </>
                        )}
                        <hr />
                        <div className="row mb-3">
                            <label for="text-color" className="col-sm-4 col-form-label">
                                Text Color:
                              </label>
                            <div className="col-sm-8">
                              <input className="form-control form-control-color" type="color" value={textColor} onChange={(e) => setTextColor(e.target.value)} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label for="text-hover-color" className="col-sm-4 col-form-label">
                                Text Hover Color:
                            </label>
                            <div className="col-sm-8">
                              <input className="form-control form-control-color" type="color" value={textHoverColor} onChange={(e) => setTextHoverColor(e.target.value)} />
                            </div>
                        </div>
                        <hr />
                        <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">Border Style</label>
                            <div className="col-sm-8">
                                <select className="form-select" value={borderStyle} onChange={(e) => setBorderStyle(e.target.value)}>
                                  <option value="none">None</option>
                                  <option value="solid">Solid</option>
                                  <option value="dashed">Dashed</option>
                                  <option value="dotted">Dotted</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label for="border-weight" className="col-sm-4 col-form-label">Border Weight</label>
                            <div className="col-sm-8">
                                <input className="form-control" type="number" value={borderWeight} onChange={(e) => setBorderWeight(e.target.value)} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label for="border-color" className="col-sm-4 col-form-label">Border color</label>
                            <div className="col-sm-8">
                                <input className="form-control form-control-color" type="color" value={borderColor} onChange={(e) => setBorderColor(e.target.value)} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label for="border-radius" className="col-sm-4 col-form-label">Border radius</label>
                            <div className="col-sm-8">
                                <input className="form-range" type="range" min="0" max="50" value={borderRadius} onChange={(e) => setBorderRadius(e.target.value)} />
                              {borderRadius} px
                            </div>
                        </div>
                        <hr />
                        <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">Add Padding</label>
                            <div className="col-sm-8">
                                <div className="row mb-3">
                                    <label for="padding-top" className="col-sm-4 col-form-label">Top</label>
                                    <div className="col-sm-8">
                                        <input className="form-control" type="number" value={paddingTop} onChange={(e) => setPaddingTop(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label for="padding-right" className="col-sm-4 col-form-label">Right</label>
                                    <div className="col-sm-8">
                                        <input className="form-control" type="number" value={paddingRight} onChange={(e) => setPaddingRight(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label for="padding-bottom" className="col-sm-4 col-form-label">Bottom</label>
                                    <div className="col-sm-8">
                                        <input className="form-control" type="number" value={paddingBottom} onChange={(e) => setPaddingBottom(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label for="padding-left" className="col-sm-4 col-form-label">Left</label>
                                    <div className="col-sm-8">
                                        <input className="form-control" type="number" value={paddingLeft} onChange={(e) => setPaddingLeft(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">Text shadow</label>
                            <div className="col-sm-8">
                                <div className="row mb-3">
                                    <label for="text-shadow-x" className="col-sm-4 col-form-label">X</label>
                                    <div className="col-sm-8">
                                        <input className="form-control" type="number" value={textShadowX} onChange={(e) => setTextShadowX(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label for="text-shadow-y" className="col-sm-4 col-form-label">Y</label>
                                    <div className="col-sm-8">
                                        <input className="form-control" type="number" value={textShadowY} onChange={(e) => setTextShadowY(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label for="text-shadow-blur" className="col-sm-4 col-form-label">Blur</label>
                                    <div className="col-sm-8">
                                        <input className="form-control" type="number" value={textShadowBlur} onChange={(e) => setTextShadowBlur(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label for="text-shadow-color" className="col-sm-4 col-form-label">Color</label>
                                    <div className="col-sm-8">
                                        <input className="form-control form-control-color" type="color" value={textShadowColor} onChange={(e) => setTextShadowColor(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">Box shadow</label>
                            <div className="col-sm-8">
                                <div className="row mb-3">
                                    <label for="box-shadow-x" className="col-sm-4 col-form-label">X</label>
                                    <div className="col-sm-8">
                                        <input className="form-control" type="number" value={boxShadowX} onChange={(e) => setBoxShadowX(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label for="box-shadow-y" className="col-sm-4 col-form-label">Y</label>
                                    <div className="col-sm-8">
                                        <input className="form-control" type="number" value={boxShadowY} onChange={(e) => setBoxShadowY(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label for="box-shadow-blur" className="col-sm-4 col-form-label">Blur</label>
                                    <div className="col-sm-8">
                                        <input className="form-control" type="number" value={boxShadowBlur} onChange={(e) => setBoxShadowBlur(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label for="box-shadow-color" className="col-sm-4 col-form-label">Color</label>
                                    <div className="col-sm-8">
                                        <input className="form-control form-control-color" type="color" value={boxShadowColor} onChange={(e) => setBoxShadowColor(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">Text alignment</label>
                            <div className="col-sm-8">
                                <select className="form-select" value={textAlign} onChange={(e) => setTextAlign(e.target.value)}>
                                  <option value="left">Left</option>
                                  <option value="center">Center</option>
                                  <option value="right">Right</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="col-sm-4 col-form-label">Font family</label>
                            <div className="col-sm-8">
                                <select className="form-select" value={fontFamily} onChange={(e) => setFontFamily(e.target.value)}>
                                  <option value="Arial">Arial</option>
                                  <option value="Courier New">Courier New</option>
                                  <option value="Times New Roman">Times New Roman</option>
                                  <option value="Comic Sans MS">Comic Sans MS</option>
                                  <option value="Lucida Sans Unicode">Lucida Sans Unicode</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label for="font-size" className="col-sm-4 col-form-label">Font size</label>
                            <div className="col-sm-8">
                                <input className="form-control" type="number" value={fontSize} onChange={(e) => setFontSize(e.target.value)} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label for="line-height" className="col-sm-4 col-form-label">Line height</label>
                            <div className="col-sm-8">
                                <input className="form-control" type="number" value={lineHeight} onChange={(e) => setLineHeight(e.target.value)} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div id="results col-md-12">
                        <p className="mb-1"><b>CSS Code</b></p>
                        <pre className="language-css"><code id="button-style">{generatedCSS}</code></pre>
                        <button className='btn btn-primary float-end' onClick={handleCopyButtonClick}>
                          Copy CSS Code
                        </button>
                    </div>
                </div>
            </div>
            <br /> <br /> 
            <div className="row">
                <div className="col-md-12">
                    <h2>What is CSS Button Generator?</h2>
                    <p>
                        CSS Button Generator is a convenient online tool designed for creating customized buttons for websites. It offers a simple interface that eliminates the need for manual coding. This tool is particularly beneficial for individuals lacking design skills or those who prefer not to spend excessive time designing buttons from scratch. Let's delve into CSS Button Generator and discover how it can empower you to create visually appealing buttons that seamlessly integrate with your website's style.
                    </p>

                    <h3>Features of CSS Button Generator</h3>
                    <p>
                        CSS Button Generator boasts an array of impressive features to assist you in fashioning professional-looking buttons for your website. Let's explore some of its notable features:
                    </p>
                    <ul>
                        <li>
                            <b>Button Styles:</b> CSS Button Generator offers a diverse range of styles, including flat, gradient, and 3D, enabling you to choose the perfect style to complement your website's design.
                        </li>
                        <li>
                            <b>Button Colors:</b> Customize the color of your buttons to harmonize with your website's color scheme. CSS Button Generator provides a color picker tool with an extensive range of colors to select from.
                        </li>
                        <li>
                            <b>Button Effects:</b> Elevate the interactivity and engagement of your buttons with CSS Button Generator's collection of effects. Experiment with hover effects and click effects to enhance user experience.
                        </li>
                        <li>
                            <b>Button Code:</b> Once you've finalized your button design, CSS Button Generator generates the necessary code. Just copy and paste the code into your website's HTML file, and you're all set! Your buttons are ready to go.
                        </li>
                    </ul>
                    <h2>Why Use CSS Button Generator?</h2>
                    <p>
                        CSS Button Generator offers numerous advantages for web developers seeking professional-looking buttons for their websites. Consider the following benefits:
                    </p>
                    <ul>
                        <li>
                            <b>Easy to Use:</b> CSS Button Generator features a user-friendly interface that eliminates the need for coding knowledge. Design and customize buttons effortlessly, saving valuable time and effort.
                        </li>
                        <li>
                            <b>Time-Saver:</b> With CSS Button Generator, you can create customized buttons within minutes. Say goodbye to time-consuming manual designs and welcome the efficiency of this intuitive tool.
                        </li>
                        <li>
                            <b>Consistent Design:</b> Maintain a consistent design aesthetic throughout your website with buttons that seamlessly blend with your chosen style. CSS Button Generator ensures your buttons harmonize with your website's overall look and feel.
                        </li>
                        <li>
                            <b>Cross-Browser Compatibility:</b> Generate code that is compatible with all major web browsers, guaranteeing a consistent button appearance across various devices and platforms.
                        </li>
                    </ul>
                    <h2>Conclusion</h2>
                    <p>
                        In conclusion, CSS Button Generator is an invaluable tool for web developers aiming to create professional-looking buttons for their websites. With its easy-to-use interface, time-saving features, consistent design options, and compatibility across different web browsers, CSS Button Generator becomes an essential tool to have. By utilizing CSS Button Generator, you can enhance your website's visual appeal, improve user experience, and even boost your search engine ranking. Take advantage of this SEO-friendly tool today and watch your website's buttons come to life with style and functionality.
                    </p>
                </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default ButtonStyler;

