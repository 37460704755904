import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const GradientGenerator = () => {
  const pageTitle = 'CSS Gradient Generator - Free Gradient Generator Tool | OnlineToolsFor.com';
    const pageDescription = 'Enhance your web design projects with our free CSS gradient generator. Choose from linear and radial gradients, custom colors, and styles for stunning gradient backgrounds. Perfect for designers of all levels, our user-friendly tool takes your web design to the next level. Try it now!';



  const [gradientType, setGradientType] = useState("linear");
  const [gradientPosition, setGradientPosition] = useState("top");
  const [colors, setColors] = useState([
    { value: "#ff0000", position: 0 },
    { value: "#0000ff", position: 80 },
  ]);

  const handleDirectionChange = (event) => {
    setGradientType(event.target.value);
    setGradientPosition("top");
  };

  const handlePositionChange = (position) => {
    setGradientPosition(position);
  };

  const handleAddColor = () => {
    setColors([...colors, { value: "#EEFF00", position: 50 }]);
  };

  const handleDeleteColor = (index) => {
    const updatedColors = [...colors];
    updatedColors.splice(index, 1);
    setColors(updatedColors);
  };

  const handleColorChange = (index, newColor) => {
    const updatedColors = [...colors];
    updatedColors[index].value = newColor;
    setColors(updatedColors);
  };

  const handlePositionSliderChange = (index, newPosition) => {
    const updatedColors = [...colors];
    updatedColors[index].position = newPosition;
    setColors(updatedColors);
  };

  const gradientStyle = {
    background: getGradient(),
    WebkitBackground: `-webkit-${getGradient()}`,
    MozBackground: `-moz-${getGradient()}`,
    width: "100%",
    height: "200px",
  };

  const [cssCode, setCssCode] = useState("");
  const codeRef = useRef();

  const updateCssCode = () => {
    const gradientCss = getGradient();
    const webkitGradientCss = `-webkit-${getGradient()}`;
    const mozGradientCss = `-moz-${getGradient()}`;
    setCssCode(`${gradientCss};\n${webkitGradientCss};\n${mozGradientCss}`);
  };
  

  const handleCopyCode = () => {
    if (codeRef.current) {
      codeRef.current.select();
      document.execCommand("copy");
    }
  };

  useEffect(() => {
    updateCssCode();
  }, [gradientType, gradientPosition, colors]);

  return (
    <>
     <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
        </Helmet>
      <header className="pt-5">
        <div className="container px-lg-5">
          <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div className="m-4 m-lg-5">
              <h1 className="display-5 fw-bold">CSS Gradient Generator</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container px-lg-5">
        <nav aria-label="breadcrumb">
          <ol className="py-1 my-2 breadcrumb">
          <li class="breadcrumb-item"><Link to="/">Home</Link></li>
            <li class="breadcrumb-item"><Link to="/css-generators">CSS Generators</Link></li>
            <li className="breadcrumb-item active" aria-current="page">
              CSS Gradient Generator
            </li>
          </ol>
        </nav>
      </div>
      {/* // <!-- Page Content--> */}
      <section className="pt-4 pb-5">
        <div className="container px-lg-5">
          {/* <!-- Page Features--> */}
          <div className="row gx-lg-5">
            <section>
              <p>
                Are you tired of using plain colors on your website or app? Are
                you interested in generating captivating <b>gradients</b> that
                will mesmerize your viewers? Look no further than our{" "}
                <b>CSS Gradient Generator</b>. With our exceptional tool, you
                can effortlessly produce personalized gradients, enabling you to
                infuse your designs with richness and intricacy.
              </p>
              <div className="row">
                <div className="col-md-12"></div>
              </div>
              <div className="row">
              <div className="col-md-6">
                  <div
                    className="gradient-preview mb-4"
                    style={gradientStyle}
                  ></div>
                  <div className="mb-4">
                    <h3>Gradient Position:</h3>
                    <div className="btn-group d-flex flex-wrap">
                      {[
                        "top",
                        "topRight",
                        "right",
                        "bottomRight",
                        "bottom",
                        "bottomLeft",
                        "left",
                        "topLeft",
                      ].map((position) => (
                        <button
                          key={position}
                          type="button"
                          className={`btn btn-outline-primary ${
                            gradientPosition === position ? "active" : ""
                          } mb-2`}
                          onClick={() => handlePositionChange(position)}
                        >
                          {position}
                        </button>
                      ))}

                      {["radial", "elliptical"].includes(gradientType) && (
                        <button
                          type="button"
                          className="btn btn-outline-primary mb-2 ms-2 ms-md-0" // Use Bootstrap spacing classes
                          onClick={() => handlePositionChange("center")}
                        >
                          Center
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="gradientType" className="form-label">
                    <h3>Select Gradient Type:</h3> 
                    </label>
                    <select
                      id="gradientType"
                      className="form-select"
                      value={gradientType}
                      onChange={handleDirectionChange}
                    >
                      <option value="linear">Linear</option>
                      <option value="radial">Radial</option>
                      <option value="elliptical">Elliptical</option>
                    </select>
                  </div>

                  <div className="color-controls mb-4">
                    <h3>Colors:</h3>
                    {colors.map((color, index) => (
                      <div key={index} className="mb-3">
                        <input
                          type="color"
                          value={color.value}
                          onChange={(e) =>
                            handleColorChange(index, e.target.value)
                          }
                        />
                        <label className="ms-2">Position:</label>
                        <input
                          type="range"
                          value={color.position}
                          min="0"
                          max="100"
                          onChange={(e) =>
                            handlePositionSliderChange(index, e.target.value)
                          }
                          className="form-range ms-2"
                        />
                        {index >= 2 && (
                          <button
                            type="button"
                            className="btn btn-danger ms-2"
                            onClick={() => handleDeleteColor(index)}
                          >
                            Delete Color
                          </button>
                        )}
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handleAddColor}
                    >
                      Add Color
                    </button>
                  </div>
                </div>
                
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="gradient-properties">
                    <div className="mt-3">
                      <h4>CSS Code:</h4>
                      <textarea
                        style={{ minHeight: "200px" }}
                        ref={codeRef}
                        className="form-control"
                        value={cssCode}
                        readOnly
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary float-end mt-3"
                      onClick={handleCopyCode}
                    >
                      Copy Code
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );

  function getGradient() {
    const colorStops = colors.map(
      (color) => `${color.value} ${color.position}%`
    );
  
    switch (gradientType) {
      case "linear":
        return `linear-gradient(${getLinearDirection()}, ${colorStops.join(", ")})`;
      case "radial":
        return `radial-gradient(${getRadialDirection()}, ${colorStops.join(", ")})`;
      case "elliptical":
        return `radial-gradient(${getEllipticalDirection()}, ${colorStops.join(", ")})`;
      default:
        return "";
    }
  }
  
  

  function getLinearDirection() {
    switch (gradientPosition) {
      case "top":
        return "to bottom";
      case "topRight":
        return "to bottom right";
      case "right":
        return "to right";
      case "bottomRight":
        return "to top right";
      case "bottom":
        return "to top";
      case "bottomLeft":
        return "to top left";
      case "left":
        return "to left";
      case "topLeft":
        return "to bottom left";
      default:
        return "to bottom";
    }
  }

  function getRadialDirection() {
    if (gradientPosition === "center") {
      return "circle at center";
    } else {
      switch (gradientPosition) {
        case "top":
          return "circle at top";
        case "topRight":
          return "circle at top right";
        case "right":
          return "circle at right";
        case "bottomRight":
          return "circle at bottom right";
        case "bottom":
          return "circle at bottom";
        case "bottomLeft":
          return "circle at bottom left";
        case "left":
          return "circle at left";
        case "topLeft":
          return "circle at top left";
        default:
          return "circle at center";
      }
    }
  }

  function getEllipticalDirection() {
    if (gradientPosition === "center") {
      return "ellipse at center";
    } else {
      switch (gradientPosition) {
        case "top":
          return "ellipse at 50% 0%";
        case "topRight":
          return "ellipse at 100% 0%";
        case "right":
          return "ellipse at 100% 50%";
        case "bottomRight":
          return "ellipse at 100% 100%";
        case "bottom":
          return "ellipse at 50% 100%";
        case "bottomLeft":
          return "ellipse at 0% 100%";
        case "left":
          return "ellipse at 0% 50%";
        case "topLeft":
          return "ellipse at 0% 0%";
        default:
          return "ellipse at center";
      }
    }
  }
};

export default GradientGenerator;
