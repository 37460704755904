import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const PercentageCalculator = () => {
  const pageTitle = 'Percentage Calculator - Calculate Percentages Quickly and Easily | onlinetoolsfor.com';
    const pageDescription = 'Use our free Percentage Calculator to quickly and easily calculate percentages for a variety of purposes, including determining percentage increases, decreases, and differences. Simply enter the initial value and the final value, and our calculator will do the rest. Our Percentage Calculator also includes step-by-step explanations of the calculations, so you can understand how to calculate percentages yourself. Try it out today on onlinetoolsfor.com!';



  const [values, setValues] = useState({
    txtAmt1: '',
    txtPer1: '',
    skbRes1: '',
    txtAmt2: '',
    txtPer2: '',
    skbRes2: '',
    txtAmt3: '',
    txtPer3: '',
    skbRes3: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const calculateResult1 = () => {
    const amt = parseFloat(values.txtAmt1);
    const per = parseFloat(values.txtPer1);
    const res = (amt / 100) * per;
    setValues((prevValues) => ({ ...prevValues, skbRes1: res.toFixed(2) }));
  };

  const calculateResult2 = () => {
    const amt = parseFloat(values.txtAmt2);
    const per = parseFloat(values.txtPer2);
    const res = (per / amt) * 100;
    setValues((prevValues) => ({ ...prevValues, skbRes2: res.toFixed(2) }));
  };

  const calculateResult3 = () => {
    const amt = parseFloat(values.txtAmt3);
    const per = parseFloat(values.txtPer3);
    const aPr = per / 100;
    const res = amt / aPr;
    setValues((prevValues) => ({ ...prevValues, skbRes3: res.toFixed(2) }));
  };

  return (
    <>
    <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
        </Helmet>
      <header class="pt-5">
    <div class="container px-lg-5">
        <div class="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div class="m-4 m-lg-5">
                <h1 class="display-5 fw-bold">Percentage Calculator</h1>
            </div>
        </div>
    </div>
</header>
<div class="container px-lg-5">
    <nav aria-label="breadcrumb">
        <ol class="py-1 my-2 breadcrumb">
            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
            <li class="breadcrumb-item"><Link to="/online-calculators">Online Calculators</Link></li>
            <li class="breadcrumb-item active" aria-current="page">Percentage Calculator</li>
        </ol>
    </nav>
</div>
{/* <!-- Page Content--> */}
<section class="pt-4 pb-5">
    <div class="container px-lg-5">
        {/* <!-- Page Features--> */}
        <div class="row gx-lg-5">
            <p class="mb-4">
                Calculating <b>percentages</b> can sometimes be a tedious task, requiring manual calculations and careful attention to detail. However, with the help of our user-friendly <b>Percentage Calculator</b>, you can effortlessly calculate percentages in no time. Whether you need to determine a percentage of a given number or find the percentage increase or decrease between two numbers, our online tool is here to assist you. In this article, we will explore how our Percentage Calculator works, its benefits, and why you should choose onlinetoolsfor.com for your percentage calculation needs.
            </p>
            <br /><br />
            <div className="col-md-12">
      {/* First calculation */}
      <div className="row skb-padding">
        <div className="col-sm-12">
          <div className="input-group">
            <label className="input-group-text"> What is </label>
            <input
              type="text"
              className="form-control"
              name="txtPer1"
              value={values.txtPer1}
              onChange={handleChange}
              onKeyUp={calculateResult1}
            />
            <label className="input-group-text"> % of </label>
            <input
              type="text"
              className="form-control"
              name="txtAmt1"
              value={values.txtAmt1}
              onChange={handleChange}
              onKeyUp={calculateResult1}
            />
            <label className="input-group-text"> = </label>
            <label className="form-control skb-ans">{values.skbRes1}</label>
          </div>
        </div>
      </div>

      {/* Second calculation */}
      <br />
      <div className="row skb-padding">
        <div className="col-sm-12">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              name="txtPer2"
              value={values.txtPer2}
              onChange={handleChange}
              onKeyUp={calculateResult2}
            />
            <label className="input-group-text"> is what percent of </label>
            <input
              type="text"
              className="form-control"
              name="txtAmt2"
              value={values.txtAmt2}
              onChange={handleChange}
              onKeyUp={calculateResult2}
            />
            <label className="input-group-text"> = </label>
            <label className="form-control skb-ans">{values.skbRes2}</label>
          </div>
        </div>
      </div>

      {/* Third calculation */}
      <br />
      <div className="row skb-padding">
        <div className="col-sm-12">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              name="txtAmt3"
              value={values.txtAmt3}
              onChange={handleChange}
              onKeyUp={calculateResult3}
            />
            <label className="input-group-text"> is </label>
            <input
              type="text"
              className="form-control"
              name="txtPer3"
              value={values.txtPer3}
              onChange={handleChange}
              onKeyUp={calculateResult3}
            />
            <label className="input-group-text"> % of what ? </label>
            <label className="form-control skb-ans">{values.skbRes3}</label>
          </div>
        </div>
      </div>
    </div>
      </div>
        <br />
        <br />
        <div class="row">
            <div class="col-md-12">
                <h2>What is a Percentage Calculator?</h2>
                <p>
                    A Percentage Calculator is a convenient online tool designed to simplify percentage calculations. It provides a hassle-free way to determine percentages without the need for manual calculations. With our tool, you can swiftly and precisely determine the percentage of a given number or effortlessly calculate the percentage increase or decrease between two numbers. Regardless of whether you're a student, a professional in the business world, or someone seeking assistance with routine calculations, our Percentage Calculator serves as an indispensable tool at your disposal.
                </p>

                <h2>How to Use the Percentage Calculator</h2>
                <p>
                    Using our Percentage Calculator is incredibly straightforward. Just follow these simple steps:
                </p>

                <ul>
                    <li>Enter the number you want to calculate the percentage of.</li>
                    <li>Input the percentage you wish to calculate.</li>
                    <li>Click the "Calculate" button.</li>
                </ul>
                <p>
                    Once you've followed these simple steps, our calculator will efficiently analyze the data provided and promptly furnish you with the precise outcome you require. Whether you're determining a discount, analyzing data, or performing any other percentage-related calculation, our tool is designed to make your life easier.
                </p>

                <h3>Why Use Our Percentage Calculator?</h3>
                <p>
                    There are several compelling reasons to choose our Percentage Calculator for your percentage calculations:
                </p>

                <ul>
                    <li>
                        <b>Accuracy and Efficiency:</b> Our calculator guarantees precise results every time, eliminating the potential for human error. It saves you valuable time and effort compared to manual calculations.
                    </li>
                    <li>
                        <b>User-Friendly Interface:</b> Our user-friendly design ensures a seamless experience, allowing anyone to easily navigate and utilize the calculator without any prior technical knowledge.
                    </li>
                    <li>
                        <b>Versatility:</b> Our Percentage Calculator can be used for various applications, including finding discounts, calculating taxes, analyzing statistical data, and many more. With its adaptability to various scenarios, this versatile tool proves advantageous for individuals as well as businesses, catering to a diverse range of needs and requirements.
                    </li>
                </ul>

                <h2>Conclusion</h2>
                <p>
                    At onlinetoolsfor.com, we are dedicated to providing our users with high-quality online tools and calculators to simplify their daily tasks. Our Percentage Calculator offers accuracy, efficiency, and user-friendliness, ensuring that you can calculate percentages effortlessly. Discover the ease and convenience of our Percentage Calculator today and witness for yourself how it can assist you in conserving precious time and energy during your everyday tasks. Visit onlinetoolsfor.com now and experience the convenience of our Percentage Calculator.
                </p>
            </div>
        </div>
    </div>
</section>
    </>
  );
};

export default PercentageCalculator;
