import React, { useState, useEffect, useRef } from "react";
import "./triangle-generator.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const TriangleGenerator = () => {
  const pageTitle = "CSS Triangle Generator - OnlineToolsFor.com";
  const pageDescription =
    "Easily create and customize triangles for your website or project using CSS Triangle Generator, a free online tool available on OnlineToolsFor.com. Select the size, color, border, and positioning of your triangle, and generate the corresponding CSS code with just a few clicks. Perfect for both novice and professional designers, CSS Triangle Generator streamlines the process of creating beautiful, customized triangles. Try it out today and add a touch of uniqueness to your design!";

  const triangleRef = useRef(null);
  const [val, setVal] = useState({
    size: 200,
    height: 0.5,
    angle: 0,
  });

  const [bdr, setBdr] = useState(["top", "right", "bottom", "left"]);
  const [dir, setDir] = useState("top");
  const [showBorders, setShowBorders] = useState(false);
  const [cssStyle, setCssStyle] = useState("");

  useEffect(() => {
    draw();
  }, [val, dir, showBorders]);

  const handleInputChange = (e) => {
    setVal({
      ...val,
      [e.target.name]: +e.target.value,
    });
  };

  const handlePresetButtonClick = (preset) => {
    setVal({
      ...val,
      [preset.property]: preset.value,
    });
  };

  const handleDirectionChange = (e) => {
    setDir(e.target.value);
    setBdr((prevBdr) => {
      while (prevBdr[0] !== e.target.value) {
        prevBdr.push(prevBdr.shift());
      }
      return [...prevBdr];
    });
  };

  // const handleShowBordersChange = () => {
  //   setShowBorders((prevShowBorders) => !prevShowBorders);
  // };

  const draw = () => {
    let style = "";
    const css = {
      width: "0",
      height: "0",
      border: "0 solid transparent",
    };
    css["border-" + bdr[1] + "-width"] =
      Math.round((val.size * (-val.angle + 1)) / 2) + "px";
    css["border-" + bdr[3] + "-width"] =
      Math.round((val.size * (val.angle + 1)) / 2) + "px";
    css["border-" + bdr[2]] =
      Math.round(val.size * val.height) + "px solid black";
    css["height"] = "0";
    css["width"] = "0";

    if (showBorders) {
      css["border-color"] = "black";
      css["border-style"] = "solid";
    }

    const hypotenuse = Math.round(
      Math.sqrt(
        Math.pow((val.size * (val.angle + 1)) / 2, 2) +
          Math.pow(val.size * val.height, 2)
      )
    );
    const opposite = Math.round(
      Math.sqrt(
        Math.pow(hypotenuse, 2) - Math.pow((val.size * (-val.angle + 1)) / 2, 2)
      )
    );
    css["height"] = Math.round(val.size * val.height) + "px";
    css["width"] = opposite + "px";

    Object.assign(triangleRef.current.style, css);

    Object.entries(css).forEach(([k, v]) => {
      v = v.replace(/(\d+(px)?)/g, '<span className="size">$1</span>');
      style +=
        '<span className="prop">' +
        k +
        '</span>: <span className="value">' +
        v +
        "</span>;\n";
    });

    setCssStyle(style);
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <header className="pt-5">
        <div className="container px-lg-5">
          <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div className="m-4 m-lg-5">
              <h1 className="display-5 fw-bold">CSS Triangle Generator</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container px-lg-5">
        <nav aria-label="breadcrumb">
          <ol className="py-1 my-2 breadcrumb">
            <li class="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li class="breadcrumb-item">
              <Link to="/css-generators">CSS Generators</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              CSS Triangle Generator
            </li>
          </ol>
        </nav>
      </div>
      <section className="pt-4 pb-5">
        <div className="container px-lg-5">
          <div className="row gx-lg-5">
            <p>
              <b>CSS Triangle Generator</b> is a free online tool available on
              onlinetoolsfor.com that allows users to create customizable
              triangles using CSS. This simple tool permits you to alter the
              size, variety, border, and placement of your triangle prior to
              producing CSS code that you can easily copy and paste into your
              project. Whether you're an expert website designer or just getting
              started with coding, CSS Triangle Generator is an excellent asset
              that can save you time and hassle when it comes to making the
              ideal triangle. This tool is destined to turn into a go-to asset
              for anyone wishing to add a customized triangle to their website
              or undertaking, because of its simple plan and robust
              functionality. Try CSS Triangle Generator today and start creating
              beautiful, customized triangles in no time!
            </p>
            <br />
            <br />
            <br />

            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="ctrl">
                    <ul id="values">
                      <li>
                        <label>
                          <span>
                            <b>Size</b>
                          </span>
                          <input
                            name="size"
                            type="range"
                            min="0"
                            max="400"
                            step="any"
                            value={val.size}
                            onChange={handleInputChange}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <span>
                            <b>Height</b>
                          </span>
                          <input
                            name="height"
                            type="range"
                            min="0"
                            max="2"
                            step="any"
                            value={val.height}
                            onChange={handleInputChange}
                          />
                          <button
                            onClick={() =>
                              handlePresetButtonClick({
                                property: "height",
                                value: 0.5,
                              })
                            }
                          >
                            1:2
                          </button>
                          <button
                            onClick={() =>
                              handlePresetButtonClick({
                                property: "height",
                                value: 1,
                              })
                            }
                          >
                            1:1
                          </button>
                          <button
                            onClick={() =>
                              handlePresetButtonClick({
                                property: "height",
                                value: 0.866,
                              })
                            }
                          >
                            √3:2 (equilateral)
                          </button>
                        </label>
                      </li>
                      <li>
                        <label>
                          <span>
                            <b>Angle</b>
                          </span>
                          <input
                            name="angle"
                            type="range"
                            min="-1"
                            max="1"
                            step="any"
                            value={val.angle}
                            onChange={handleInputChange}
                          />
                        </label>
                      </li>
                    </ul>
                    <ul id="direction">
                      <li>
                        <label>
                          <input
                            type="radio"
                            name="dir"
                            value="top"
                            checked={dir === "top"}
                            onChange={handleDirectionChange}
                          />
                          <span>↑</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input
                            type="radio"
                            name="dir"
                            value="right"
                            checked={dir === "right"}
                            onChange={handleDirectionChange}
                          />
                          <span>→</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input
                            type="radio"
                            name="dir"
                            value="bottom"
                            checked={dir === "bottom"}
                            onChange={handleDirectionChange}
                          />
                          <span>↓</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input
                            type="radio"
                            name="dir"
                            value="left"
                            checked={dir === "left"}
                            onChange={handleDirectionChange}
                          />
                          <span>←</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  {/* <input
                    id="show"
                    type="checkbox"
                    name=""
                    checked={showBorders}
                    onChange={handleShowBordersChange}
                  />
                  <label htmlFor="show"> show all borders</label> */}
                  <div className="out">
                    <div className="tri" ref={triangleRef}></div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <p className="mb-1 mt-4">
                  <b>CSS Code</b>
                </p>
                <pre className="language-css">
                  <code dangerouslySetInnerHTML={{ __html: cssStyle }}></code>
                </pre>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-md-12">
              <h2>
                CSS Triangle Generator: Create Unique Shapes for Your Web
                Projects
              </h2>
              <p>
                Are you tired of using the same old rectangular and square
                shapes on your website? Would you like to infuse your designs
                with a hint of creativity? Look no further than the CSS Triangle
                Generator! With the help of this effective tool, you can easily
                generate personalized triangles with just a few clicks. In this
                article, we will discuss the advantages of utilizing CSS
                triangles and guide you on utilizing the CSS Triangle Generator
                to craft your very own distinctive shapes.
              </p>

              <h2>What Are CSS Triangles?</h2>
              <p>
                CSS triangles are shapes created entirely with CSS. They are
                typically used to create arrows, chevrons, and other geometric
                shapes. Unlike traditional images, CSS triangles can be resized,
                recolored, and manipulated using CSS. This makes them ideal for
                responsive web design and other dynamic web projects.
              </p>

              <h2>Why Use CSS Triangles?</h2>
              <p>
                CSS triangles have a number of advantages over traditional
                images. For one, they are much lighter and faster to load. CSS
                triangles can greatly enhance your website's performance,
                particularly on mobile devices. They offer easy customization
                options as they are created using CSS. Harnessing the might of
                just a few lines of code, you can effortlessly tailor the size,
                color, and position of your CSS triangles to your liking.
                Moreover, CSS triangles are scalable, ensuring they appear
                visually appealing on any device, regardless of screen size or
                resolution.
              </p>

              <h2>How to Use the CSS Triangle Generator</h2>
              <p>
                Using the CSS Triangle Generator is easy. Simply visit the
                website and choose your desired triangle shape. You can select
                different pre-made shapes like equilateral triangles, isosceles
                triangles, and right triangles. After selecting your desired
                shape, you can further customize it by adjusting its size.
              </p>

              <h2>Conclusion</h2>
              <p>
                For web designers seeking to craft distinctive and dynamic
                shapes on their websites, CSS triangles serve as a powerful
                tool. With the CSS Triangle Generator, creating custom triangles
                has never been easier. By implementing the guidance and
                strategies provided in this article, you can craft captivating
                and adaptable designs that will amaze your visitors and enhance
                your website's performance.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TriangleGenerator;
