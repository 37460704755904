import Navigation from "./Navigation";
import About from "./pages/About";
import Home from "./pages/Home";
import Webtools from "./pages/Webtools";
import Privacypolicy from "./pages/Privacypolicy";
import TermsConditions from "./pages/TermsConditions";
import CssGenerators from "./pages/CssGenerators";
import SeoTools from "./pages/SeoTools";
import OnlineCalculators from "./pages/OnlineCalculators";
import WordCharacterCounter from "./pages/web-tools/word-character-counter/WordCharacterCounter";
import CaseChanger from "./pages/web-tools/case-changer-tool/CaseChanger";
import CssMinifierTool from "./pages/web-tools/css-minifier/CssMinifierTool";
import CssBeautifier from "./pages/web-tools/css-beautifier-tool/CssBeautifier";
import ExcelConverter from "./pages/web-tools/php-array-to-excel/ExcelConverter";
// import DateConverterForm from "./pages/web-tools/php-date-format-code-generator/DateConverterForm";
import ImageToWebPConverter from "./pages/web-tools/image-to-webp-converter/ImageToWebPConverter";
import BorderRadiusControl from "./pages/css-generators/css-border-radius-generator/BorderRadiusControl";
import BoxShadowGenerator from "./pages/css-generators/css-boxshadow-generator/BoxShadowGenerator";
import TriangleGenerator from "./pages/css-generators/css-triangle-generator/TriangleGenerator";
import TitleDescriptionTool from "./pages/seo-tools/title-description-tool/TitleDescriptionTool";
import SlugConverter from "./pages/seo-tools/text-to-slug/SlugConverter";
import AgeCalculator from "./pages/online-calculators/age-calculator/AgeCalculator";
import LoanCalculator from "./pages/online-calculators/loan-calculator/LoanCalculator";
import PayPalCalculator from "./pages/online-calculators/paypal-fee-calculator/PayPalCalculator";
import PercentageCalculator from "./pages/online-calculators/percentage-calculator/PercentageCalculator";
import ButtonStyler from "./pages/css-generators/css-button-generator/ButtonStyler";
import GradientGenerator from "./pages/css-generators/css-gradient-generator/GradientGenerator";
import ContactForm from "./pages/ContactForm";
import { Routes, Route } from "react-router-dom";
import Footer from "./Footer";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Routes>
        <Route
          path="/css-generators/css-button-generator"
          element={<ButtonStyler />}
        />
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/web-tools" element={<Webtools />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/css-generators" element={<CssGenerators />} />
        <Route path="/seo-tools" element={<SeoTools />} />
        <Route path="/online-calculators" element={<OnlineCalculators />} />
        <Route
          path="/web-tools/css-beautifier-tool"
          element={<CssBeautifier />}
        />
        <Route
          path="/web-tools/word-character-counter"
          element={<WordCharacterCounter />}
        />
        <Route path="/web-tools/case-changer-tool" element={<CaseChanger />} />
        <Route
          path="/web-tools/css-minifier-tool"
          element={<CssMinifierTool />}
        />
        <Route
          path="/web-tools/php-array-to-excel"
          element={<ExcelConverter />}
        />
        {/* <Route
          path="/web-tools/php-date-format-code-generator"
          element={<DateConverterForm />}
        /> */}
        <Route
          path="/web-tools/image-to-webp-converter"
          element={<ImageToWebPConverter />}
        />
        <Route
          path="/css-generators/css-border-radius-generator"
          element={<BorderRadiusControl />}
        />
        <Route
          path="/css-generators/css-boxshadow-generator"
          element={<BoxShadowGenerator />}
        />
        <Route
          path="/css-generators/css-triangle-generator"
          element={<TriangleGenerator />}
        />
        <Route
          path="/seo-tools/title-description-tool"
          element={<TitleDescriptionTool />}
        />
        <Route path="/seo-tools/text-to-slug" element={<SlugConverter />} />
        <Route
          path="/online-calculators/age-calculator"
          element={<AgeCalculator />}
        />
        <Route
          path="/online-calculators/loan-calculator"
          element={<LoanCalculator />}
        />
        <Route
          path="/online-calculators/paypal-fee-calculator"
          element={<PayPalCalculator />}
        />
        <Route
          path="/online-calculators/percentage-calculator"
          element={<PercentageCalculator />}
        />
        <Route
          path="/css-generators/css-gradient-generator"
          element={<GradientGenerator />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
