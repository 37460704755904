// OnlineCalculators.js
import React from 'react'
import { Link } from 'react-router-dom'
import OnlineCalculatorsCardParent from '../OnlineCalculatorsCardParent';
import { Helmet } from 'react-helmet';

function OnlineCalculators() {
    const pageTitle = 'Free Tools for Loan, Percentage, PayPal Fees, Returns, Age, and More | onlinetoolsfor.com';
    const pageDescription = 'Need to calculate a loan payment, find a percentage, determine PayPal fees, calculate returns on investments, or figure out your age? Look no further than onlinetoolsfor.com\'s collection of free online calculators. Our calculators are designed to help you quickly and easily solve a variety of financial and math-related problems. Try them out today!';
  return (
    <>
    <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
        </Helmet>
      <header className="pt-5">
        <div className="container px-lg-5">
            <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                <div className="m-4 m-lg-5">
                    <h1 className="display-5 fw-bold">Online Calculators</h1>
                    <p className="fs-4">
                        list of all Online Online Calculators.
                    </p>
                </div>
            </div>
        </div>
    </header>
    <div className="container px-lg-5 pb-5">
        <nav aria-label="breadcrumb">
            <ol className="py-1 my-2 breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Online Calculators</li>
            </ol>
        </nav>
    </div>
      {/* <!-- Page Content--> */}
      <section className="pt-4">
          <div className="container px-lg-5">
              {/* <!-- Page Features--> */}
              <div className="row gx-lg-5">
                  <OnlineCalculatorsCardParent />
              </div>
          </div>
      </section>
    </>
  )
}

export default OnlineCalculators;
