import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

function Home() {
    const pageTitle = 'Online Tools & Generators | Web Design, Development & SEO - OnlineToolsFor.com';
    const pageDescription = 'OnlineToolsFor.com provides a comprehensive collection of online tools, CSS generators, text editors, and online converters for web designers, developers, and SEO professionals. Our resources make it easy to create and customize your website, optimize your content for search engines, and analyze your website\'s performance. Improve your online presence today with our powerful and user-friendly tools.';
  return (
    <>
    <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
        </Helmet>
      {/* <!-- Header--> */}
      <header className="py-5">
          <div className="container px-lg-5">
              <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                  <div className="m-4 m-lg-5">
                      <h1 className="display-5 fw-bold">Boost Your Productivity with onlinetoolsfor.com</h1>
                      <p className="fs-4">
                          The Ultimate Resource for Web Designers and Developers - Discover the Best Online Tools to Boost Your Productivity.
                      </p>
                      <Link className="btn btn-primary btn-lg" to="/About">Read More</Link>
                  </div>
              </div>
          </div>
      </header>
      {/* <!-- Page Content--> */}
      <section className="pt-4">
          <div className="container px-lg-5">
              {/* <!-- Page Features--> */}
              <div className="row gx-lg-5">
                  <div className="col-lg-6 col-xxl-4 mb-5">
                      <div className="card bg-light border-0 h-100">
                          <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                              <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                                  <i className="bi bi-collection"></i>
                              </div>
                              <h2 className="fs-4 fw-bold"><Link to="css-generators">CSS Generators</Link></h2>
                              <p className="mb-0">
                                  Find a large range of CSS Generators to help you visualize how certain properties work, with full code generated with each tool.
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-6 col-xxl-4 mb-5">
                      <div className="card bg-light border-0 h-100">
                          <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                              <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                                  <i className="bi bi-tools"></i>
                              </div>
                              <h2 className="fs-4 fw-bold"><Link to="web-tools">Web Tools</Link></h2>
                              <p className="mb-0">
                                  Find an extent of web tools that can improve your online experience. From efficiency to creativity, we take care of you. Explore now!
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-6 col-xxl-4 mb-5">
                      <div className="card bg-light border-0 h-100">
                          <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                              <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                                  <i className="bi bi-search"></i>
                              </div>
                              <h2 className="fs-4 fw-bold"><Link to="seo-tools">SEO Tools</Link></h2>
                              <p className="mb-0">
                                  Elevate your website's search ranking with our SEO tools. Discover opportunities, monitor progress, and optimize content for success. Try now!
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-6 col-xxl-4 mb-5">
                      <div className="card bg-light border-0 h-100">
                          <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                              <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                                  <i className="bi bi-calculator"></i>
                              </div>
                              <h2 className="fs-4 fw-bold"><Link to="online-calculators">Online Calculators</Link></h2>
                              <p className="mb-0">
                                  Perform complex calculations effectively with our web-based calculators. We have many calculators to suit your needs. Try them now!
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
              <hr />
              <br /><br />
              <div className="row gx-lg-5">
                  <p>
                      Welcome to our platform, OnlineToolsFor.com, your go-to destination for a wide array of internet-based utilities meticulously designed to streamline your tasks and enhance your overall efficiency. With our comprehensive range of services, including CSS Generators, Web Tools, SEO Tools, and Online Calculators, professionals like web designers, developers, content producers, and individuals striving for swift and effective task completion can experience the transformative benefits our products offer.
                  </p>
                  <h2>CSS Generators</h2>
                  <p>
                      Unleash your creativity and effortlessly craft visually appealing and functional CSS components for your website with our specialized CSS Generator tools. Seamlessly generate stunning gradients using our CSS Gradient Generator with just a few clicks. Personalize the box shadow effect of your elements using our CSS Box Shadow Generator, while our CSS Border Radius Generator simplifies the creation of rounded corners. For customizable triangles, our CSS Triangle Generator proves invaluable, and our CSS Button Generator empowers you to create distinct custom buttons tailored to your website's aesthetics. Lastly, optimize your website's loading speed with our CSS Sprite Generator, leveraging CSS sprites for faster performance.
                  </p>
                  <h2>Web Tools</h2>
                  <p>
                      Simplify your content creation process with our user-friendly Web Tools. Counting words and characters in your content becomes effortless with our Word and Character Counter tool. Instantly change the case of your text and eliminate manual formatting with our Case Changer Tool. Boost your website's loading speed by optimizing CSS code using our CSS Minifier Tool, while enhancing code readability with our CSS Beautifier Tool. Convert PHP arrays to Excel spreadsheets effortlessly and save valuable time with our PHP Array to Excel Converter tool. Additionally, our PHP Date Format Code Generator empowers developers to create custom date formats efficiently, ultimately saving precious time.
                  </p>
                  <h2>SEO Tools</h2>
                  <p>
                      Elevate your website's visibility and performance with our practical SEO Tools. Transform lengthy text into concise, SEO-friendly slugs using our Text to Slug tool, optimizing the readability and accessibility of your URLs. Craft compelling page titles and meta descriptions effortlessly, boosting click-through rates and enhancing your SEO strategy, with our Title & Description Tool.
                  </p>
                  <h2>Online Calculators</h2>
                  <p>
                      Simplify complex calculations and streamline your work with our comprehensive Online Calculators. Our Paypal Fee Calculator makes it a breeze to calculate fees for PayPal transactions. Accurately calculate loan payments and customize results for any type of loan using our versatile Loan Calculator. Solve percentage problems effortlessly with our intuitive Percentage Calculator. Lastly, determine age accurately and effortlessly with our Age Calculator, offering customizable and user-friendly interfaces that provide quick and precise results.
                  </p>
                  <p>
                      At OnlineToolsFor.com, our primary objective is to provide you with superior-grade, user-friendly internet-based tools that not only simplify your work but also enhance overall efficiency. Embark on an exploration of our website today to discover the complete range of services we offer, enabling you to harness the full power of our tools and begin reaping their benefits without delay!
                  </p>
              </div>
              <br /><br /><br />
          </div>
      </section>
    </>
  );
}

export default Home;
