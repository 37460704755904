// TitleDescriptionTool.js
import React, { useState, useEffect } from "react";
import "./TitleDescriptionTool.css"; // Make sure to import the CSS file
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const TitleDescriptionTool = () => {
  const pageTitle = 'Title & Description Generator Tool | OnlineToolsFor';
    const pageDescription = 'Our free online tool helps you create compelling titles and descriptions for your website that drive traffic and improve search engine rankings. Try it now!';



  const [title, setTitle] = useState("");
  const [focusKeyword, setFocusKeyword] = useState("");
  const [description, setDescription] = useState("");
  const [ggResult, setGgResult] = useState("");
  const [countTitle, setCountTitle] = useState(0);
  const [countKey, setCountKey] = useState(0);
  const [countDesc, setCountDesc] = useState(0);

  const colors = ["#f44336", "#fbc02d", "#4caf50"];

  const handleTitleChange = (event) => {
    const value = event.target.value;
    setTitle(value);
    setCountTitle(value.length);
    updateSeoTitle(value);
  };

  const handleKeyChange = (event) => {
    const value = event.target.value;
    setFocusKeyword(value);
    setCountKey(value.length);
    setGgResult(value);
  };

  const handleDescChange = (event) => {
    const value = event.target.value;
    setDescription(value);
    setCountDesc(value.length);
    updateSeoDesc(value);
  };

  const updateSeoTitle = (title) => {
    // Update SEO title logic
    // You can customize this logic based on your requirements
    // For now, it sets the SEO title to the same value as the input title
    document.getElementById("gg-title").innerHTML = title;
  };

  const updateSeoDesc = (desc) => {
    // Update SEO description logic
    // You can customize this logic based on your requirements
    // For now, it sets the SEO description to the same value as the input description
    document.getElementById("gg-desc").innerHTML = desc;
  };

  useEffect(() => {
    // Initial values
    const textTi = "Lorem Ipsum is simply dummy text of the printing happyy";
    const textFk = "Focus Keyword";
    const textUr =
      "http://example.com/your-title-url-<b>focus-keyword</b>-more-description";
    const textDe =
      "<b>Focus Keyword</b> with Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industry's standard dummy text es verynice.";

    setGgResult(textFk);
    updateSeoTitle(textTi);
    updateSeoDesc(textDe);
  }, []);

  const onBlur = () => {
    if (!title.trim() && !focusKeyword.trim() && !description.trim()) {
      // Reset to initial values
      setGgResult("Focus Keyword");
      updateSeoTitle("Lorem Ipsum is simply dummy text of the printing happyy");
      updateSeoDesc(
        "<b>Focus Keyword</b> with Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industry's standard dummy text es verynice."
      );
    }
  };

  return (
    <>
    <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
        </Helmet>

      <header class="pt-5">
    <div class="container px-lg-5">
        <div class="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div class="m-4 m-lg-5">
                <h1 class="display-5 fw-bold">Title & Description Tool</h1>
            </div>
        </div>
    </div>
</header>
<div class="container px-lg-5">
    <nav aria-label="breadcrumb">
        <ol class="py-1 my-2 breadcrumb">
            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
            <li class="breadcrumb-item"><Link to="/seo-tools">SEO Tools</Link></li>
            <li class="breadcrumb-item active" aria-current="page">Title & Description Tool</li>
        </ol>
    </nav>
</div>
{/* <!-- Page Content--> */}
<section class="pt-4 pb-5">
    <div class="container px-lg-5">
        {/* <!-- Page Features--> */}
        <div class="row gx-lg-5">
            <p class="mb-4">
                <b>Title & Description Tool</b> provides the exact length of the text for both the title and meta description. This is an important feature as search engines have specific character limits for these elements, and exceeding the limits can result in truncation or even penalties. By providing the exact length of the text, our tool ensures that your titles and descriptions meet the requirements of search engines and are fully displayed to users, maximizing their effectiveness.
            </p>
            <br /><br />
      <div className="col-md-12">
        <h2 className="cgg">
          Title & Description Tool <span className="gg">Google!</span>
        </h2>
        <div className="form-group">
          <label htmlFor="title">
            <span id="count-title">{countTitle}</span> / 58
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            placeholder="Title"
            maxLength="58"
            value={title}
            onChange={handleTitleChange}
            onBlur={onBlur}
          />
        </div>
        <br />
        <div className="form-group">
          <label htmlFor="key">
            <span id="count-key">{countKey}</span> / 15
          </label>
          <input
            type="text"
            className="form-control"
            id="key"
            placeholder="Focus Keyword"
            maxLength="15"
            value={focusKeyword}
            onChange={handleKeyChange}
            onBlur={onBlur}
          />
        </div>
        <br />
        <div className="form-group">
          <label htmlFor="desc">
            <span id="count-desc">{countDesc}</span> / 155
          </label>
          <textarea
            className="form-control"
            id="desc"
            placeholder="Description"
            maxLength="155"
            spellCheck="false"
            value={description}
            onChange={handleDescChange}
            onBlur={onBlur}
          />
        </div>
        <br />
        <div className="google">
          <h2 className="cgg">
            <span className="gg_1">Google!</span>
          </h2>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="gg-result"
              readOnly
              value={ggResult}
            />
          </div>
          <div className="box">
            <h3 id="gg-title"></h3>
            <cite id="gg-url"></cite>
            <span id="gg-desc"></span>
          </div>
        </div>
      </div>
      </div>
        <br />
        <br />
        <div class="row">
            <div class="col-md-12">
                <p>
                    Welcome to <b>onlinetoolsfor.com</b>, where we offer different free internet based tools to assist you with your site needs. Our <b>Title & Description Tool</b> is a simple yet powerful resource that provides you with the exact length of your text for SEO purposes.
                </p>
                <h2>Why Is the Exact Length of Text Important for SEO?</h2>
                <p>
                    Search engines have specific character limits for titles and meta descriptions, and exceeding these limits can result in truncation or penalties. It's crucial to ensure that your titles and descriptions meet these requirements to maximize their effectiveness and improve your search engine rankings.
                </p>
                <h2>How Does Our Title & Description Tool Work?</h2>
                <p>
                    Our Title & Description Tool is easy to use - simply enter your text into the provided fields, and our tool will calculate the exact length for you. You can use this information to adjust your titles and descriptions to fit within the limits and improve their effectiveness for SEO purposes.
                </p>
                <h2>Why Choose Our Title & Description Tool?</h2>
                <p>
                    Our Title & Description Tool is a fast and easy way to get the exact length of your text for SEO purposes. It's free, requires no registration or downloads, and can be used by anyone who needs to optimize their website for search engines.
                </p>
                <h2>Conclusion</h2>
                <p>
                    If you're looking for a simple and effective way to get the exact length of your titles and meta descriptions for SEO purposes, our Title & Description Tool is the perfect solution. Try it out today to ensure that your website meets the requirements of search engines and is fully optimized for maximum visibility and effectiveness.
                </p>
            </div>
        </div>
    </div>
</section>
    </>
  );
};

export default TitleDescriptionTool;
