// OnlineCalculatorsCardParent.js
import React from "react";
import OnlineCalculatorsCard from "./OnlineCalculatorsCard";

const OnlineCalculatorsCardData = [
  {
    iconClass: "bi bi-credit-card-2-front",
    title: "Paypal Fee Calculator",
    link: "/online-calculators/paypal-fee-calculator",
    description:
      "Easily calculate PayPal fees for transactions. Save time and avoid surprises. Accurate, up-to-date rates. Streamline your online payments now.",
  },
  {
    iconClass: "bi bi-cash",
    title: "Loan Calculator",
    link: "/online-calculators/loan-calculator",
    description:
      "Calculate loan payments with ease. Accurate and customizable tools for any type of loan. Plan for the future and make informed financial decisions.",
  },
  {
    iconClass: "bi bi-percent",
    title: "Percentage Calculator",
    link: "/online-calculators/percentage-calculator",
    description:
      "Perform quick and accurate percentage calculations. Solve any percentage problem with ease. Simplify your calculations and save time today.",
  },
  {
    iconClass: "bi bi-calendar-week",
    title: "Age Calculator",
    link: "/online-calculators/age-calculator",
    description:
      "Determine age accurately and effortlessly. Customizable and intuitive interface. Get quick and accurate results. Calculate age in seconds.",
  },

  // Add more data items as needed
];

const OnlineCalculatorsCardParent = () => {
  return (
    <div className="row">
      {OnlineCalculatorsCardData.map((feature, index) => (
        <OnlineCalculatorsCard key={index} {...feature} />
      ))}
    </div>
  );
};

export default OnlineCalculatorsCardParent;
